import React, { useCallback, useState } from 'react';
import { Form, Divider, Input, Button, message, DatePicker } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { useMutation } from 'react-apollo';
import moment from 'moment';

import ReportColumnSelector from '../../../components/ReportColumnSelector';
import { DOWNLOAD_REPORT_MUTATION } from '../../../queries';
import {
  DownloadReport,
  DownloadReportVariables,
} from '../../../../../types/DownloadReport';

interface Props {
  form: WrappedFormUtils;
  projectId: string;
}

const CreateInstantReportForm: React.FC<Props> = ({
  form: { getFieldDecorator, validateFields, resetFields },
  projectId,
}) => {
  const [date, setDate] = useState({
    startDate: 0,
    endDate: 0,
  });

  const [downloadInstantReportMutation, { loading }] = useMutation<
    DownloadReport,
    DownloadReportVariables
  >(DOWNLOAD_REPORT_MUTATION, {
    onError: (error) => {
      message.error(error.message);
    },
    onCompleted: (reportData) => {
      if (reportData.exportReport.url) {
        message.success('Report Generated');
        window.open(reportData.exportReport.url, '_blank'); // download report

        resetFields();
      }
    },
  });

  const disabledStartDate = useCallback(
    (currentStartDate: moment.Moment | undefined): boolean => {
      if (currentStartDate) {
        if (currentStartDate.valueOf() > moment().valueOf()) {
          // disable future date
          return true;
        }
        if (date.endDate) {
          return currentStartDate.valueOf() > date.endDate; // disable dates after end date
        }
      }
      return false;
    },
    [date],
  );

  const disabledEndDate = useCallback(
    (currentEndDate: moment.Moment | undefined): boolean => {
      if (currentEndDate) {
        if (currentEndDate.valueOf() > moment().valueOf()) {
          // disable future date
          return true;
        }
        if (date.startDate) {
          return currentEndDate.valueOf() <= date.startDate; // disable dates before start date
        }
      }
      return false;
    },
    [date],
  );


  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      validateFields(async (fieldErrors, values) => {
        if (!fieldErrors) {
          let { columns, startDate, endDate, header, footer } = values;
  
          // Converting start date and end date to moment objects
          const momentStartDate = moment(startDate);
          const momentEndDate = moment(endDate);
  
          // Setting start time to the beginning of the day (00:00 AM)
          momentStartDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  
          // Setting end time to the end of the day (11:59 PM)
          momentEndDate.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
  
          downloadInstantReportMutation({
            variables: {
              columns,
              startDate: momentStartDate.valueOf(),
              endDate: momentEndDate.valueOf(),
              header,
              footer,
              projectId,
            },
          });
        }
      });
    },
    [validateFields, downloadInstantReportMutation, projectId],
  );
  

  return (
    <Form onSubmit={handleSubmit}>
      <Divider />

      <Form.Item label="Date Selector" required />

      <div className="flex xs:flex-col sm:flex-row">
        <Form.Item>
          {getFieldDecorator('startDate', {
            rules: [
              {
                required: true,
                message: 'Date is required',
              },
            ],
          })(
            <DatePicker
              disabledDate={disabledStartDate}
              placeholder="Start Date"
              onChange={(dateSelected) => {
                if (dateSelected) {
                  setDate((prevState) => ({
                    ...prevState,
                    startDate: dateSelected.valueOf(),
                  }));
                }
              }}
            />,
          )}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator('endDate', {
            rules: [
              {
                required: true,
                message: 'Date is required',
              },
            ],
          })(
            <DatePicker
              disabledDate={disabledEndDate}
              placeholder="End Date"
              onChange={(dateSelected) => {
                if (dateSelected) {
                  setDate((prevState) => ({
                    ...prevState,
                    endDate: dateSelected.valueOf(),
                  }));
                }
              }}
            />,
          )}
        </Form.Item>
      </div>

      <Divider />

      <Form.Item label="Add Header">
        {getFieldDecorator('header')(
          <Input.TextArea
            placeholder="Header..."
            autoSize={{ minRows: 3, maxRows: 8 }}
          />,
        )}
      </Form.Item>

      {/* <Form.Item
        label={
          <span className="break-words">
            Categories / Project Elements / Working Items
          </span>
        }
      >
        {getFieldDecorator('items')(
          <TreeSelect
            projectId={projectId}
            multiple
            placeholder="Select Categories / Project Elements / Working Items"
          />,
        )}
      </Form.Item> */}

      <Divider />

      <div className="w-full px-3 py-2 text-left text-blue-600 bg-blue-200 rounded-md xs:mb-2 sm:mb-0">
        Select the fields to be included in the Report.asdf
      </div>

      <Form.Item label="Add Columns">
        {getFieldDecorator('columns', {
          initialValue: ["financialWeightage","startDate","targetDateOfCompletion","dateOfCompletion","duration","scopeUnit","scopeQuantity","cummProgress",],
        })(<ReportColumnSelector />)}
      </Form.Item>

      <Divider />

      <Form.Item label="Add Footer">
        {getFieldDecorator('footer')(
          <Input.TextArea
            placeholder="Footer..."
            autoSize={{ minRows: 3, maxRows: 8 }}
          />,
        )}
      </Form.Item>

      <Divider dashed />

      <Button type="primary" htmlType="submit" loading={loading}>
        Submit
      </Button>
    </Form>
  );
};

export default Form.create<Props>({ name: 'createInstantReport' })(
  CreateInstantReportForm,
);
