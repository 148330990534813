import React, { useCallback, useState, useContext } from 'react';
import styled from 'styled-components';
import { Form, Input, Button, Icon, message } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import validator from 'validator';
import { get } from 'lodash-es';

import AuthContext from '../../../contexts/AuthContext';

interface Props {
  form: WrappedFormUtils;
  onLogin?: () => void;
}

const LoginForm: React.FC<Props> = ({
  form: { getFieldDecorator, validateFields },
  onLogin,
}) => {
  const [loading, setLoading] = useState<'LOGIN' | 'LOGIN_WITH_OTP' | 'OTP_LOGIN' | null>(
    null,
  );

  const [isOtpFormVisible,setIsOtpFormVisible] = useState(false);

  const { signInWithUsernameAndPassword,signInWithPhoneNumber } = useContext(AuthContext);

  const handleLogin = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      validateFields(async (fieldErrors, values) => {
        if (!fieldErrors) {
          setLoading('LOGIN');
          const { success, error } = await signInWithUsernameAndPassword(
            values.username,
            values.password,
          );
          if (success && onLogin) {
            onLogin();
          } else if (error) {
            message.error(error);
            setLoading(null);
          }
        }
      });
    },
    [onLogin, signInWithUsernameAndPassword, validateFields],
  );

  const handleLoginWithOTPInitate = useCallback(() => {
    // Disable validation for the password field
    validateFields(['username'], { first: false }, async (fieldErrors, values) => {
      const valid = !isNaN(parseFloat(values.username)) && isFinite(values.username) && values.username.length === 10;
  
      if (valid) {
        setLoading('LOGIN_WITH_OTP');
        try {
          const { resendOTP } = await signInWithPhoneNumber(values.username);
          const { success, error } = await resendOTP();
          if (success) {
            message.success("An OTP has been sent your mobile");
            setLoading(null);
            setIsOtpFormVisible(true);
            // onLogin();
          } else if (error) {
            message.error(error);
            setLoading(null);
          }
        } catch (error) {
          const errorMessage = get(
            error,
            'response.data.message',
            'Something went wrong. Please try again',
          );
          message.error(errorMessage);
          setLoading(null);
        }
      } else {
        message.error("Enter Valid Mobile");
      }
    });
  }, [validateFields]);
  
  
  const handleLoginWithOTP = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    validateFields(async (fieldErrors, values) => {

      let valid = !isNaN(parseFloat(values.otp)) && isFinite(values.otp) && values.otp.length == 6;

      if (!fieldErrors && valid) {

        setLoading('OTP_LOGIN');
        try {
          console.log({otppppp:values.otp})
          const { verifyOTP } = await signInWithPhoneNumber(
            values.otp
          );
          const {success,error} = await verifyOTP(values.otp);
          if (success) {
            message.success("Login Success");
            setLoading(null);
            // onLogin();
          } else if (error) {
            message.error('Invalid OTP');
            setLoading(null);
          }

        } catch (error) {
          const errorMessage = get(
            error,
            'response.data.message',
            'Something went wrong. Please try again',
          );
          message.error(errorMessage);
          setLoading(null);
        }
      }
      else{
        message.error("Enter Valid OTP")
      }
    });

  }, [validateFields]);


  return (
    isOtpFormVisible ? 
    <Form onSubmit={handleLoginWithOTP}>
    <Form.Item>
      {getFieldDecorator('otp', {
        // @ts-ignore
        validate: [
          {
            trigger: 'onChange',
            rules: [
              {
                required: true,
                message: 'OTP is required',
              },
            ],
          },
          {
            trigger: 'onBlur',
            rules: [
              {
                validator: async (rules: any, value: string) => {
                  const isValid = value.length === 6; 

                  if (!isValid) {
                    throw new Error('Invalid OTP');
                  }
                },
                message: 'Invalid OTP',
              },
            ],
          },
        ],
        validateFirst: true,
      })(
        <Input
          placeholder="Enter OTP"
          type="number"
          maxLength={6} 
          prefix={<StyledIcon type="lock" />}
        />,
      )}
    </Form.Item>

      <Form.Item>
        <StyledButton
          type="primary"
          htmlType="submit"
          loading={loading === 'OTP_LOGIN'}
        >
          Login
        </StyledButton>
      </Form.Item>

      <StyledButton
        type="link"
        htmlType="button"
        loading={loading === 'LOGIN_WITH_OTP'}
        onClick={()=>setIsOtpFormVisible(false)}
      >
        Back to Login
      </StyledButton>

    </Form>
    :
    <Form onSubmit={handleLogin}>
      <Form.Item>
        {getFieldDecorator('username', {
          // @ts-ignore
          validate: [
            {
              trigger: 'onChange',
              rules: [
                {
                  required: true,
                  message: 'Username is required',
                },
              ],
            },
            {
              trigger: 'onBlur',
              rules: [
                {
                  validator: async (rules: any, value: string) => {
                    const isValid =
                      validator.isEmail(value) ||
                      // TODO: Check local before sending data
                      validator.isMobilePhone(value, 'en-IN');

                    if (!isValid) {
                      throw new Error(
                        'Username must be a valid phone number or email',
                      );
                    }
                  },
                  message: 'Username must be a valid phone number or email',
                },
              ],
            },
          ],
          validateFirst: true,
        })(
          <Input
            placeholder="Email/Phone Number"
            prefix={<StyledIcon type="user" />}
          />,
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('password', {
          // @ts-ignore
          validate: [
            {
              trigger: 'onChange',
              rules: [
                {
                  required: true,
                  message: 'Password is required',
                },
              ],
            },
            {
              trigger: 'onBlur',
              rules: [
                {
                  min: 8,
                  message: 'Password should be 8 characters long',
                },
              ],
            },
          ],
        })(
          <Input.Password
            placeholder="Password"
            prefix={<StyledIcon type="lock" />}
          />,
        )}
      </Form.Item>
      <Form.Item>
        <StyledButton
          type="primary"
          htmlType="submit"
          loading={loading === 'LOGIN'}
        >
          Login
        </StyledButton>
      </Form.Item>
      <StyledButton
        type="link"
        htmlType="button"
        loading={loading === 'LOGIN_WITH_OTP'}
        onClick={handleLoginWithOTPInitate}
      >
        Login with OTP
      </StyledButton>
    </Form>
  );
};

export default Form.create<Props>({ name: 'login' })(LoginForm);

const StyledButton = styled(Button)`
  &&& {
    width: 100%;
  }
`;

const StyledIcon = styled(Icon)`
  opacity: 0.5;
`;
