/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

import { User } from '../types';

interface LeadInfo {
  name: string;
  organisation: string;
  email: string;
  phone: string;
}

const AuthContext = React.createContext<{
  authToken?: string;
  user?: User;
  signInWithUsernameAndPassword: (
    username: string,
    password: string,
  ) => Promise<{ success: boolean; error?: string }>;
  signInWithPhoneNumber: (
    phoneNumber: string,
  ) => Promise<{
    verifyOTP: (otp: string) => Promise<{ success: boolean; error?: string }>;
    resendOTP: () => Promise<{ success: boolean; error?: string }>;
  }>;
  signOut: () => Promise<void>;
  initiateResetPassword: (email: string) => Promise<void>;
  resetPassword: (email: string, newPassword: string) => Promise<void>;
  updateToken: (updatedToken: string) => void;
  leadInfoCaptured?: LeadInfo;
  setLeadInfoCaptured: (leadInfoCapturedState: LeadInfo) => void;
}>({
  signInWithUsernameAndPassword: async (username, password) => ({
    success: false,
  }),
  signInWithPhoneNumber: async phoneNumber => ({
    verifyOTP: async otp => ({ success: false }), 
    resendOTP: async () => ({ success: false }), 
  }),
  signOut: async () => {},
  initiateResetPassword: async email => {},
  resetPassword: async (email, newPassword) => {},
  updateToken: updatedToken => {},
  leadInfoCaptured: undefined,
  setLeadInfoCaptured: (leadInfoCapturedState: LeadInfo) => {},
});

export default AuthContext;
