import React, { useCallback, useState } from 'react';
import { Form, Card, Input, Icon, Button, message } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { RouteComponentProps, Link } from 'react-router-dom';
import qs from 'querystring';
import Axios from 'axios';
import { get } from 'lodash-es';

interface Props extends RouteComponentProps {
  form: WrappedFormUtils;
}

const UpdatePassword: React.FC<Props> = ({
  form: { getFieldDecorator, resetFields, validateFields },
  history: {
    location: { search },
  },
}) => {
  const { token } = qs.parse(search.substr(1)) as {
    email: string;
    token: string;
  };

  const [loading, setLoading] = useState(false);

  const [passwordUpdated, setPasswordUpdated] = useState(false);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      validateFields(async (fieldErrors, values) => {
        if (!fieldErrors) {
          setLoading(true);
          try {
            const { data: success } = await Axios.post<string>(
              '/reset-password',
              {
                password: values.password,
                token,
              },
              {
                baseURL: process.env.REACT_APP_API_BASE_URL,
              },
            );
            if (success) {
              resetFields();
              setPasswordUpdated(true);
            }
          } catch (error) {
            const errorMessage = get(
              error,
              'response.data.message',
              'Something went wrong. Please try again',
            );
            message.error(errorMessage);
          } finally {
            setLoading(false);
          }
        }
      });
    },
    [resetFields, token, validateFields],
  );

  return (
    <div className="flex items-center justify-center w-screen h-screen bg-dark-background-color">
      <div className="w-80">
        <Card className="xs:mx-4 sm:mx-4">
          {passwordUpdated ? (
            <>
              <div className="mb-4 text-center text-success-color">
                <svg
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  className="inline w-20 h-20"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="mb-4 text-base text-center text-heading-color">
                Password Updated Successfully
              </div>
              <Link
                to="/login"
                className="flex items-center justify-center text-sm"
              >
                <span className="mr-2 text-primary-color">
                  <Icon type="arrow-left" className="fill-current" />
                </span>
                <span className="text-primary-color">Login to Supersite</span>
              </Link>
            </>
          ) : (
            <>
              <div className="mb-4 text-base font-medium text-heading-color">
                Update Password
              </div>
              <Form onSubmit={handleSubmit}>
                <Form.Item>
                  {getFieldDecorator('password', {
                    rules: [
                      { required: true, message: 'Password is required' },
                    ],
                  })(
                    <Input.Password
                      prefix={<Icon type="lock" className="opacity-50" />}
                      placeholder="New Password"
                    />,
                  )}
                </Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="w-full"
                  loading={loading}
                >
                  Reset Password
                </Button>
              </Form>
            </>
          )}
        </Card>
      </div>
    </div>
  );
};

export default Form.create<Props>({ name: 'updatePassword' })(UpdatePassword);
