import React, { forwardRef, useMemo } from 'react';
import { Checkbox, Row, Result } from 'antd';
import { useQuery } from 'react-apollo';
import { range } from 'lodash-es';

import { REPORT_FIELDS_QUERY } from '../queries';
import { ReportFields } from '../../../types/ReportFields';

interface Props {
  className?: string;
  style?: React.CSSProperties;
}

const ReportDateRangeSelector: React.FC<Props> = forwardRef(
  ({ className, style, ...restProps }: Props, ref: any) => {
    const { data: allFields, loading, error } = useQuery<ReportFields>(
      REPORT_FIELDS_QUERY,
    );

    const content = useMemo(() => {
      if (loading) {
        return (
          <div className="h-full max-w-xs mt-2">
            {range(4).map((value) => (
              <div key={value} className="w-2/3 h-3 mb-4 skeleton" />
            ))}
          </div>
        );
      }

      if (error) {
        return <Result status="warning" subTitle={error.message} />;
      }

      if (allFields) {
        console.log({allFields})
        return (
          <Checkbox.Group
            ref={ref}
            className={className}
            style={style}
            {...restProps}
          >
            {allFields.reportFields.map((field) => {
              return (
                <Row className="mb-2" key={field.key}>
                  <Checkbox value={field.key}>{field.label}</Checkbox>
                </Row>
              );
            })}
          </Checkbox.Group>
        );
      }

      return null;
    }, [allFields, ref, loading, error, className, style, restProps]);

    return content;
  },
);

export default ReportDateRangeSelector;
